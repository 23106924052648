import React from 'react';
import styled from 'styled-components';
import ListItemContainer from '../../components/ThankyouPages/ListItemContainer';
import withThankyouWrapper from '../../components/ThankyouPages/withThankyouWrapper';

const Container = styled.div`
  width: 70%;
  max-width: 1170px;
  margin: auto;
`;

const Header = styled.div`
  max-width: 800px;
  margin: auto;
  text-align: center;
  padding-top: 2.5vh;
`;

const Image = styled.img`
  width: 100%;
`;

const Text = styled.h3`
  font-size: ${({ fontSize }) => fontSize || '2em'};
  font-weight: 400;
  margin: 30px;

  a {
    color: #4aa35b;
  }
`;

const Section = styled.section`
  width: calc(96% - 40px);
  max-width: 660px;
  padding: 10px 20px 10px;
  margin: auto;
  border-radius: 10px;
  box-shadow: 0 3px 8px #555;
  margin-bottom: 10px;
  background: ${({ primary }) => (primary ? '#ebebeb' : '#1c3d5c')};
  border: ${({ primary }) =>
    primary ? '4px solid #1c3d5c' : '4px solid #fff'};
  color: ${({ secondary }) => secondary && '#fff'};
`;

const sectionOne = [
  "We'll be calling you in the next two minutes",
  "We'll take you through our radically simple mortgage process. It really is super simple.",
  "We'll verify your information and help you lock in a mortgage you can be confident in.",
];

const sectionTwo = [
  'Can I expect a lower monthly payment?',
  'Will I close within 30 days?',
  'Can I manage my mortgage online?',
  'Should I switch to a 15-year fixed?',
  'Will I get updates that will keep my informed?',
];

// Style this
const ElThankYou = () => (
  <Container>
    <Header>
      <h1>
        You've been matched with our featured provider, and America's largest
        mortgage lender!
      </h1>
      <Container>
        <Image
          src="https://s3-us-west-1.amazonaws.com/offers.printfingertech.net/images/rocketmortagge.png"
          alt="rocketmortgage"
        />
      </Container>
      <h4>NMLS #3030</h4>
    </Header>

    <Section primary>
      <h2>Here's what's next...</h2>
      <ListItemContainer items={sectionOne} />
    </Section>

    <Section secondary>
      <h2>5 Questions Rocket Mortgage Can Answer for you:</h2>
      <ListItemContainer items={sectionTwo} />
    </Section>

    <Text fontSize="12px">
      ¹ Based on Rocket Mortgage data in comparison to public data records.
    </Text>
  </Container>
);

export default withThankyouWrapper(ElThankYou);
